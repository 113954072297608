.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: white;
}

a {
	color: inherit;
}

.outro {
	margin: 30px auto;
	color: #666666;
	font-size: 0.8rem;
}

.loading {
	display: block;
	margin: 10px 0;
	color: #AAAAAA;
	user-select: none;
}

h2 {
	font-weight: lighter;
}
