@mixin maintext {
	font-size: 2rem;
	display: block;
	width: max-content;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	text-align: center;
	padding: 0 10px;

	color: #FFFFFF;
	font-weight: lighter;
}

.settings {
	&.wrapper {
		width: calc(100% - 30px);
		max-width: 1000px;
		display: block;

		summary {
			user-select: none;
			cursor: pointer;
		}
	}

	&.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
		padding-left: 0;

		li {
			// user-select: none;
			list-style: none;
			padding: 5px 7px;
			display: inline-block;
			border: 1px solid #AAAAAA;
		}
	}

	&.help {
		display: block;
		margin: 0 auto;
		color: #444;
		font-size: 0.8rem;
		width:100%;
		text-align: center;
	}

	&.allgood {
		@include maintext;

		text-shadow: 0 0 7px rgba(255, 255, 255, 0.6),
		0 0 10px rgba(255, 255, 255, 0.9),
		0 0 21px rgba(255, 255, 255, 0.8),
		0 0 42px rgba(0, 255, 170, 1),
		0 0 82px rgba(0, 255, 170, 0.9),
		0 0 92px rgba(0, 255, 170, 0.8),
		0 0 102px rgba(0, 255, 170, 0.6);
	}

	&.error {
		@include maintext;

		color: #FFFFFF;
		font-weight: lighter;
		text-shadow: 0 0 7px rgba(255, 255, 255, 0.6),
		0 0 10px rgba(255, 255, 255, 0.9),
		0 0 21px rgba(255, 255, 255, 0.8),
		0 0 42px rgba(255, 0, 0, 1),
		0 0 82px rgba(255, 0, 0, 0.9),
		0 0 92px rgba(255, 0, 0, 0.8),
		0 0 102px rgba(255, 0, 0, 0.6);
	}

	&.element {
		&.enabled {
			opacity: 70%;
			color: darkseagreen;
		}

		&.disabled {
			opacity: 70%;
			color: indianred;
		}

		&.major {
			font-size: 1.2rem;
			opacity: 100%;
			font-weight: bold;
			color: rgb(200, 80, 80);
		}
	}

	&.other {
		color: #AAAAAA;
	}

	h2 {
		margin-bottom: 10px;
	}
}

.divider {
	border: none;
	border-top: 1px solid #AAAAAA;
}
