@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Light.ttf") format("truetype");
	font-weight: 300
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Regular.ttf") format("truetype")
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-SemiBold.ttf") format("truetype");
	font-weight: 600
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Bold.ttf") format("truetype");
	font-weight: 700
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-ExtraBold.ttf") format("truetype");
	font-weight: 800
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-Italic.ttf") format("truetype");
	font-style: italic
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic
}

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic
}

* {
	box-sizing: border-box;
}

body {
	background-color: #121212;
	margin: 0;
	font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

h1 {
	font-size: 3rem;
	font-weight: lighter;
	text-align: center;
}
